.image-clipper {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    .clipper-zone {
        width: calc(35% - 30px);

        .content {
            position: relative;
            height: 100%;
            width: 100%;
            border: 1px solid lightgrey;
            .clipper {
                position: relative;
                height: 100%;
                width: 100%;
                display: block;
            }
        }
        .aspect-ratio-bar {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin: 15px 0;
        }
    }

    .divider {
        position: absolute;
        left: 35%;
        height: 90%;
        top: 0;
        transform: translateY(5%);
        margin: 0 auto;
    }
    .preview-zone {
        width: calc(65% - 30px);

        .preview-clipper-item {
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            [class^='img-preview-'] {
                overflow: hidden;
            }
            .clipper-item-content {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                padding-left: 15px;
            }
        }
    }
}

@ant-prefix: udesk-cem;@brand-primary: #597ef7;@primary-color: #597ef7;@hd: 0.94px;